import starGrey from "../../assets/starGrey.png";
import starGreen from "../../assets/starGreen.png";
import review1 from "../../assets/review1.jpg";
import review2 from "../../assets/review2.jpg";
import review3 from "../../assets/review3.jpg";
import review4 from "../../assets/review4.jpg";
import review5 from "../../assets/review5.jpg";
import review6 from "../../assets/review6.jpg";
import like from "../../assets/like.svg";
import user from "../../assets/user.png";
import { useState } from "react";

export const Reviews = () => {
  const [showReview, setShowReview] = useState(false);
  return (
    <>
      <p className="reviews__title">Рейтинг и отзывы</p>
      <div className="reviews__wrapper">
        <div>
          <p className="reviews__score">4.8</p>
          <div className="star__wrapper">
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGrey} alt="grey star" className="green__star" />
          </div>
          <p className="user__wrapper">
            Общий
            <img src={user} alt="user icon" width={16} height={16} />
          </p>
          <p className="reviews__number">2697</p>
        </div>
        <div>
          <ul className="lines__list">
            <li className="lines__item">
              <p className="numbers">5</p>
              <div className="first__line desktop_one">
                <div className="first__line--green"></div>
              </div>
            </li>
            <li className="lines__item">
              <p className="numbers">4</p>
              <div className="first__line desktop_two">
                <div className="second__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">3</p>
              <div className="first__line desktop_three">
                <div className="third__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">2</p>
              <div className="first__line desktop_four">
                <div className="fourth__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">1</p>
              <div className="first__line desktop_five">
                <div className="fourth__line--green"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review1}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Александр Ефремов</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">20.04.2024</p>
            </div>
            {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
          </div>
          <p className="item__text">
            Это лучшее, что я встречал. Я просто не могу поверить, я будто сплю.
            Тинькофф банк, ВЫ сделали мою жизнь!!!!! Я вас люблю! Я просто
            увидел вашу рекламу, установил и не ожидал, что уже через 2 недели я
            увижу прибыль в 100.000 рублей, это просто ШОК! Спасибо большое вам
            за возможность зарабатывать и почувствовать себя полноценным
            человеком!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Тинькофф Инвестиции</p>
            <p className="review__description">
              Александр Ефремов, спасибо за отзыв! Нам очень важно, чтобы наши
              клиенты получали удовольствие и заработок от нашего проекта. Наша
              команда делает всё, чтобы вы получили максимальное наслаждение
              пользуясь нашей разработкой!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review2}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Дмитрий Москаленко</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">19.04.2024</p>
            </div>
            {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
          </div>
          <p className="item__text">
            Я кричал и прыгал как ребенок, у меня были слезы на глазах когда я
            вложил всего лишь 15 000 рублей и через месяц увидел цифру в 300.000
            на моем счету. Я не знаю что сказать, у меня нет слов. Тинькофф
            банк, вы изменили мою жизнь навсегда! Наконец то я смог закрыть все
            свои кредиты, купить жене новый телефон! Дальше больше, спасибо
            большое ребятам! Надеюсь через 3 месяца на моем счету уже будет
            больше 1 000 000!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Тинькофф Инвестиции</p>
            <p className="review__description">
              Дмитрий, благодарим вас за положительный отзыв. Постоянно работаем
              над улучшением нашего проекта, очень рады что вам нравится
              зарабатывать вместе с нами!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review3}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Михаил Колесников</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">18.04.2024</p>
            </div>
            {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
          </div>
          <p className="item__text">
            Я не могу поверить своим глазам, ААААААА, я просто кричу, вся моя
            семья в шоке! с 5000 рублей за 2 недели финансовые брокеры сделали
            мне 500 000 рублей! Я не знаю как вы это делаете, куда вы вложили,
            но я на 7м небе от счастья. Купил билеты на море всей семье, внуки
            очень довольны и через 2 дня вылетаем на отдых. Наконец то моя семья
            сможет отдохнуть и почувствовать себя в достатке! Спасибо большое
            Тинькоффбанку за такие возможности, до сих пор не ощущаю что это
            реальность.
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Тинькофф Инвестиции</p>
            <p className="review__description">
              Михаил Колесников, спасибо за приятный отзыв! Инвестируйте,
              радуйте вашу семью и наслаждайтесь!
            </p>
          </div>
        </div>
      </div>
      {showReview && (
        <>
          <div className="item__wrapper">
            <img
              src={review4}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Ирина Мироненко</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">16.04.2024</p>
                </div>
                {/* <div className="item__likes">
                <img src={like} alt="" />
                <p className="item__like">0</p>
              </div> */}
              </div>
              <p className="item__text">
                Я не могууууу! Здесь без мата не обойтись! Это просто шок, я в
                полном а.уе! Мой муж работает на заводе, я сижу в декрете с
                маленьким ребенком. Случайно увидела вашу рекламу, рискнула и
                теперь мой муж мне завидует. Я обспечиваю всю семью, с 20 000
                рублей уже заработали более чем 700.000 рублей. Вся семья в
                шоке, муж вообще в недоумении что происходит……Спасибо лучшему
                банку Росии за такой шанс, уже подтянула знакомых и
                родственников, будем все вместе зарабатывать и наконец то
                счастливо жить!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Тинькофф Инвестиции</p>
                <p className="review__description">
                  Ирина, благодарим за ваш отзыв. Наш проект был создан
                  исключительно для того, чтобы дарить людям эмоции и
                  возможность зарабатывать хорошие деньги. Будем стараться
                  радовать вас почаще! Удачи вам!
                </p>
              </div>
            </div>
          </div>
          <div className="item__wrapper">
            <img
              src={review5}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Галина Бабич</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">14.04.2024</p>
                </div>
                {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
              </div>
              <p className="item__text">
                Ой ребятушки, вы перевернули жизнь всей моей семьи в лучшую
                сторону. Увидела рекламный баннер, скачала эту вашу разработку и
                почти сразу же мне позвонил ваш менеджер и рассказал чем вы
                занимаетесь. Конечно же я не поверила, мне уже 60 лет и я не
                верю в сказки. Но чудо произошло, мой муж в шоке, доченька
                счастливая, у нее так глаза не горели очень давно. Мы с моим
                65-м летним мужем вложили 16000 рублей и уже через 3 недели у
                нас на счету почти 400.000 руб. Я не знаю что вы там делаете, не
                сильно разбираюсь, но вы точно обспечили жизнь пенсионерки,
                которая уже не знала что его делать и как помочь доченьке и
                любимому затю. Тинькоффбанк, вы лучшие в этом МИРЕ!!!! Я бы вас
                всех там расцеловала бы!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Тинькофф Инвестиции</p>
                <p className="review__description">
                  Галина Бабич, благодарим вас за такие теплые слова в сторону
                  нашего проекта. Постоянно работаем над улучшением нашей
                  разработки, очень рады что вам нравится!
                </p>
              </div>
            </div>
          </div>
          {/* <div className="item__wrapper">
            <img
              src={review6}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Олег Мартынов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">05.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                У меня нет слов. С 500 рублей поднял 7000 за пару часов ;))
                Давно мне так не фартило. Лучшее казино в которое я играл!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Тинькофф Инвестиции
</p>
                <p className="review__description">
                  Олег Мартынов, благодарим вас за положительный отзыв.
                  Стараемся удовлетворять наших клиентов!
                </p>
              </div>
            </div>
          </div> */}
        </>
      )}
      <button
        type="button"
        className="reviews__button"
        onClick={() => {
          setShowReview((prevState) => !prevState);
        }}
      >
        {showReview ? "Скрыть отзывы" : "Все отзывы"}
      </button>
    </>
  );
};
