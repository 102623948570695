import { useEffect, useState } from "react";
import "./App.css";
import { Installed } from "./components/Installed/Installed";
import { Main } from "./components/Main/Main";
import { useReactPWAInstall } from "react-pwa-install";

function App() {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [animating, setAnimating] = useState(0);
  const [megabyte, setMegabyte] = useState(0);

  const param = new URLSearchParams(document.location.search);

  let external_id = param.get("external_id");
  let creative_id = param.get("creative_id");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let creo_id = param.get("creo_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let source = param.get("source");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let offer = param.get("offer");
  let bigo = param.get("bigo");

  if (bigo) {
    localStorage.setItem("bigo", bigo);
  } else {
    bigo = localStorage.getItem("bigo");
  }
  if (cost) {
    localStorage.setItem("cost", cost);
  } else {
    cost = localStorage.getItem("cost");
  }
  if (sub_id_1) {
    localStorage.setItem("sub_id_1", sub_id_1);
  } else {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }
  if (sub_id_2) {
    localStorage.setItem("sub_id_2", sub_id_2);
  } else {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }
  if (sub_id_3) {
    localStorage.setItem("sub_id_3", sub_id_3);
  } else {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (creo_id) {
    localStorage.setItem("creo_id", creo_id);
  } else {
    creo_id = localStorage.getItem("creo_id");
  }
  if (external_id) {
    localStorage.setItem("external_id", external_id);
  } else {
    external_id = localStorage.getItem("external_id");
  }
  if (site_id) {
    localStorage.setItem("site_id", site_id);
  } else {
    site_id = localStorage.getItem("site_id");
  }
  if (creative_id) {
    localStorage.setItem("creative_id", creative_id);
  } else {
    creative_id = localStorage.getItem("creative_id");
  }
  if (ad_campaign_id) {
    localStorage.setItem("ad_campaign_id", ad_campaign_id);
  } else {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }
  if (source) {
    localStorage.setItem("source", source);
  } else {
    source = localStorage.getItem("source");
  }

  if (offer) {
    localStorage.setItem("offer", offer);
  } else {
    offer = localStorage.getItem("offer");
  }

  useEffect(() => {
    if (localStorage.getItem("isAlreadyDownload")) {
      window.location.href =
        `https://rylxa.top/CdSr4RZD?a=b` +
        (sub_id_1 !== null ? `&sub_id_1=${sub_id_1}` : "") +
        (sub_id_2 !== null ? `&sub_id_2=${sub_id_2}` : "") +
        (sub_id_3 !== null ? `&sub_id_3=${sub_id_3}` : "") +
        (bigo !== null ? `&bigo=${bigo}` : "");
    }
  }, []);

  const animationMegabyte = () => {
    setTimeout(() => {
      setMegabyte(0.82);
    }, 1500);

    setTimeout(() => {
      setMegabyte(0.92);
    }, 2700);

    setTimeout(() => {
      setMegabyte(1.27);
    }, 4200);

    setTimeout(() => {
      setMegabyte(1.63);
    }, 5100);

    setTimeout(() => {
      setMegabyte(1.84);
    }, 6000);

    setTimeout(() => {
      setMegabyte(2.41);
    }, 7800);

    setTimeout(() => {
      setMegabyte(2.93);
    }, 9800);

    setTimeout(() => {
      setMegabyte(3.4);
    }, 11700);

    setTimeout(() => {
      setMegabyte(3.7);
    }, 13600);

    setTimeout(() => {
      setMegabyte("Installing...");
    }, 15400);
  };

  const handleClick = () => {
    const userAgent = window.navigator.userAgent;

    if (/iPhone/i.test(userAgent)) {
      window.location.href =
        `https://rylxa.top/CdSr4RZD?a=b` +
        (sub_id_1 !== null ? `&sub_id_1=${sub_id_1}` : "") +
        (sub_id_2 !== null ? `&sub_id_2=${sub_id_2}` : "") +
        (sub_id_3 !== null ? `&sub_id_3=${sub_id_3}` : "") +
        (bigo !== null ? `&bigo=${bigo}` : "");
    } else {
      pwaInstall({
        title: "Install Web App",
      })
        .then(() => {
          setAnimating(true);
          animationMegabyte();
          setTimeout(() => {
            localStorage.setItem("isAlreadyDownload", true);
            setAnimating("done");
          }, 17400);

          async function handleNotifications() {
            try {
              const permissionResult = await Notification.requestPermission();
              if (permissionResult === "granted") {
              } else {
                console.log("Notification permission denied");
              }
            } catch (error) {
              console.error("Error requesting notification permission:", error);
            }
            setAnimating("done");
          }

          setTimeout(() => {
            handleNotifications();
          }, 17700);
        })
        .catch(() => {
          setTimeout(() => {
            window.location.href =
              `https://rylxa.top/CdSr4RZD?a=b` +
              (sub_id_1 !== null ? `&sub_id_1=${sub_id_1}` : "") +
              (sub_id_2 !== null ? `&sub_id_2=${sub_id_2}` : "") +
              (sub_id_3 !== null ? `&sub_id_3=${sub_id_3}` : "") +
              (bigo !== null ? `&bigo=${bigo}` : "");
          }, 400);
        });
    }
    setTimeout(() => {
      document.querySelector(".MuiButton-containedPrimary").click();
    }, 5);
  };

  return (
    <div className="App">
      {supported() && !isInstalled() ? (
        <Main
          handleClick={handleClick}
          animating={animating}
          setAnimating={setAnimating}
          megabyte={megabyte}
          supported={true}
        />
      ) : isInstalled() ? (
        <Installed />
      ) : (
        <>
          <Main
            handleClick={handleClick}
            animating={animating}
            setAnimating={setAnimating}
            megabyte={megabyte}
            supported={false}
          />
        </>
      )}
    </div>
  );
}

export default App;
